@import "../definitions/index";

//Span complementary(search icon)
.input-span-complementary{
  border-color: $input-border-color;
  border-width: $input-border-width;
  background-color: $input-bg;
}

@include color-mode(dark) {
  input, input:active, input:focus {
    color: $body-color;
  }

  ::placeholder{
    color: #B0B0B0;
  }
}

//Readonly style
input[readonly]{
  background-color: transparent !important;
}

.ng-select-container{
  border: 2px solid #737D80 !important;
  height: 40px !important;
}

.p-multiselect, .p-multiselect-filter{
  height: 38px;
  border: 1px solid #737D80;
  box-shadow: none;
}

.p-multiselect-items{
  padding-left: 0;
}

.p-multiselect-label{
  padding: 0.4rem 0.75rem;
}



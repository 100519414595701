//Cards
.custom-card {
  border-color: var(--custom-card-border-color) !important;
  //cursor: pointer !important;
}

.custom-card .btn {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

/*
@include color-mode(light) {
  .custom-card{
    border-color: var(--custom-card-border-color) !important;
    cursor: pointer !important;
    //background-color: #fbfbfb;
  }
}*/

//Buttons
.custom-modal-btn {
  max-width: 250px;
  width: 100%;
  height: 40px;
}

.modal-round-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.modal-remove-btn {
  height: 28px;
  width: 200px;
}


//Badges
.modal-btn-badge {
  position: absolute;
  top: 0;
  left: 95%;
  width: 18px;
  height: 18px;
  margin-top: 5px;
  transform: translate(-50%, -50%) !important;
  border-radius: 50%;
  color: white;
  font-size: 10px;
}

.table-status-badge {
  margin-top: 5px;
  border-radius: 6px;
  text-align: center;
  color: white;
  max-width: 89px;
  width: 100%;
  height: 25px;
}


//General
.hover-text-secondary:hover {
  color: $secondary-active
}

//Color Picker
.color-picker-rounded-inner {
  border-radius: 50px !important;
  border: 4px solid rgba($body-bg, 1);
  width: 36px !important;
  height: 36px !important;
}

// Default colors
$primary: #19315D;
$secondary: #4BA8F7;
$success: #28A745;
$danger: #DC3545;
$warning: #FFC107;


//Custom colors
$primary-items: #9CA3A8;
$secondary-items: #737D80;
$primary-hover: #536A93;
$primary-active: #19315D;
$secondary-active: #73BCF9;
$secondary-hover: #A3D3FB;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "warning":    $warning,
  "danger":     $danger
);


//Default custom variables for dynamic theme
:root {
  --primary: #{$primary};
  --primary-hover: #{$primary-hover};
  --primary-active: #{$primary-active};
  --secondary: #{$secondary};
  --secondary-hover: #{$secondary-hover};
  --secondary-active: #{$secondary-active};
  --custom-navbar-bg: #{$primary};
  --primary-component-text-color: white;
  --secondary-component-text-color: white;
}

//Body
$body-bg: #F4F6F6;
$body-color: #4E4E4E;


//Inputs
$input-btn-focus-color: $secondary;
$input-border-color: $secondary-items;
$form-label-color: $secondary-items;
$input-bg: #ffff;


//Dropdowns
$dropdown-link-hover-color: white;
$dropdown-link-active-color: white;
$dropdown-link-hover-bg: $primary-hover;
$dropdown-link-active-bg: $primary;


//Navbar
$navbar-bg: $primary;
$navbar-light-color: white;
$navbar-light-hover-color: $secondary-hover;
$navbar-light-active-color: $secondary;


//Button
$btn-hover-color: white !important;


//Cards
$card-bg: white;







@import "../definitions/components";
@import "../definitions/colors";

//Color picker
.p-colorpicker-preview, .p-colorpicker {
  border-radius: $input-border-width !important;
  border-color: $input-border-color;
  border-width: $input-border-width;
}

.p-colorpicker-panel {
  border-radius: $input-border-width !important;
  background-color: $body-bg;
}

.p-colorpicker-hue {
  background: transparent url("/assets/images/primeng/hue.png") no-repeat left top !important;
}

.color-picker-rounded {
  border-radius: 50px !important;
  border: 4px solid rgba($body-bg, 1);
  width: 35px !important;
  height: 35px !important;
  //border-color: blue;
  //border-width: 4px;
  //box-shadow: 0 0 1px 2px red;

}


.color-picker-rounded .p-colorpicker-preview {
  border: none !important;
}


//Selector
ul.p-dropdown-items {
  padding-left: 0;
}

.p-dropdown {
  width: 100%;
}


//Chips
.p-chips-multiple-container {
  @extend .form-control;
  padding-top: 0;
  padding-bottom: 0;

  .p-chips-token {
    padding-top: 1px;
    padding-bottom: 1px;
    margin: 3px
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$font-family-base: 'Montserrat';


$h1-font-size: 68px;
$h2-font-size: 42px;
$h3-font-size: 26px;

//$font-size-base: 16px;


$font-sizes: (
  1: 22px,
  2: 20px,
  3: 18px,
  4: 16px,
  5: 14px,
  6: 12px,
);


@import "../definitions/colors";

.navbar{
  background-color: var(--custom-navbar-bg) !important;

  height: 77px;
  padding-top: 10px;
  padding-bottom: 10px;

}

.nav-link{
  color: var(--primary-component-text-color) !important;

  &:hover{
    color: var(--secondary-hover) !important;
  }

  &:active, &.active, &:focus, &:focus-within{
    color: var(--secondary-active) !important;
  }
}



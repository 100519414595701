.dataTables_filter label, .dataTables_filter .form-control {
  width: 100% !important;
}

table.dataTable>tbody>tr.odd {
  background: #f4f6f6;
}

#DataTables_Table_0_filter input {
  padding-left: 30px !important;
}

.dataTables_filter .form-control {
  padding-left: 40px;
  margin-left: 0 !important;
}

table.dataTable td, table.dataTable th {
    padding: 0px 15px;
}

table.dataTable > tbody > tr {
  height: 50px;
}

table.dataTable > thead > tr {
  height: 50px;
  color: var(--secondary);
}

table.dataTable > thead > tr > th {
  font-weight: 500;
}

.dataTables_filter .fa-search {
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding-top: 8px;
}

.dataTables_wrapper .rounded-circle {
  width: 25px;
  height: 25px;
  padding: 0px;
  display: flex;
  justify-content: center;
  margin-left: auto !important;
}

.dataTables_wrapper .rounded-circle i {
  font-size: 0.7rem;
  line-height: .7rem;
  align-self: center;
}

.dataTables_filter label {
  width: auto !important;
}

.custom-datatable {
  //@extend .border;
  border: 2px solid var(--secondary) !important;
  background-color: white;
  border-radius: 20px;
  padding: 20px;

}

/*th.sorting_asc:before,th.sorting_asc:after,th.sorting_desc:before,th.sorting_desc:after,{
  font-size: 20px;
  line-height: 15px;
}*/


/*.editRow{
border-radius: 12px;
}*/

//Pagination

//Base pagination buttons
.page-item {
  margin-left: 10px;
  margin-right: 10px;
  height: 28px;
  width: 28px;
}

.page-link {
  background-color: white !important;
  border: 1px solid var(--primary) !important;
  border-radius: 4px;
  height: 28px;
  width: 28px;
  font-size: map-get($font-sizes, 5);
  padding-top: 4px;
  padding-left: 10px;
}

//Defult pagination buttons colors
.page-link {
  color: $primary-items !important;
  border-color: $primary-items !important;
}

//Active button
.active > .page-link {
  color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}

//Previous and next buttons
.next > .page-link, .previous > .page-link {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  font-size: map-get($font-sizes, 6);
}

//Inputs
$focus-ring-box-shadow: none;
$input-border-width: 1px;


//Buttons
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-box-shadow: none;




@import "../definitions/colors";

.btn-primary {
  color: var(--primary-component-text-color) !important;
}

.btn-secondary{
  color: var(--secondary-component-text-color) !important;
}

.btn-primary{
  background-color: var(--primary);
  border-color: var(--primary) !important;


  &:active{
    background-color: var(--primary-active) !important;
  }

  &:hover{
    background-color: var(--primary-hover)!important;
  }

  &:focus{
    background-color: var(--primary-hover);
  }

}

.btn-secondary{
  background-color: var(--secondary);
  border-color: var(--secondary);


  &:active, &.active{
    background-color: var(--secondary-active) !important;
    border-color: var(--secondary-active) !important;
  }

  &:hover{
    background-color: var(--secondary-hover) !important;
    border-color: var(--secondary-hover) !important;
  }

  &:focus{
    background-color: var(--secondary-hover);
    border-color: var(--secondary-hover);
  }

}

.btn-outline-primary{
  color: var(--primary);
  border-color: var(--primary);

  //&:active, &.active{
  //  background-color: var(--primary-active) !important;
  //  border-color: var(--primary-active) !important;
  //  color: white !important;
  //}

  &:hover{
    background-color: var(--primary-hover) !important;
    border-color: var(--primary-hover) !important;
    color: white !important;
  }

  &:focus{
    background-color: var(--primary-hover) ;
    border-color: var(--primary-hover) ;
    color: white ;
  }


}

.btn-outline-secondary{
  color: var(--secondary);
  border-color: var(--secondary);

  &:active, &.active{
    background-color: var(--secondary-active) !important;
    border-color: var(--secondary-active) !important;
    color: white !important;
  }

  &:hover{
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: white !important;
  }

  &:focus{
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: white;
  }

}


/*
.btn-outline-secondary {
  @include button-outline-variant($secondary, white, lighten($secondary,5%), $secondary);
}
*/
